import { render, staticRenderFns } from "./PersonalAssets.vue?vue&type=template&id=0573e896&scoped=true&"
import script from "./PersonalAssets.vue?vue&type=script&lang=js&"
export * from "./PersonalAssets.vue?vue&type=script&lang=js&"
import style0 from "./PersonalAssets.vue?vue&type=style&index=0&id=0573e896&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0573e896",
  null
  
)

export default component.exports